
<div fxflex="row" fxflexorder="-1" style="order: -1; flex: 1 1 0%; box-sizing: border-box;">
    <ejs-dropdownlist  class="fw" 
        id='BuildingDropDown'
        #BuildingDropDown
        [dataSource]='BuildingList'
        [fields]='BuildingFields'
        placeholder='Select Building'
        [allowFiltering]='true'
        class="full-width margin-right"
        [value]="SelectedBuildingValue" 
        (change)='onBuildingDropdownListChange($event)'>                    
    </ejs-dropdownlist>
</div>
<div fxflex="row" fxflexorder="1" fxflexorder.gt-md="3" style="order: 1; flex: 1 1 0%; box-sizing: border-box;">
    <ejs-dropdownlist 
        id='FloorDropDown'
        #FloorDropDown
        [dataSource]='FloorList'
        [fields]='FloorFields'
        placeholder='Select Floor'
        [allowFiltering]='true'
        class="full-width margin-right"
        [value]="SelectedFloorValue"
        (change)='onFloorDropdownListChange($event)'>        
    </ejs-dropdownlist>
</div>
<div fxflex="row" fxflexorder="2" style="order: 2; flex: 1 1 0%; box-sizing: border-box;">
    <ejs-dropdownlist
        id='ZoneDropDown'
        #ZoneDropDown
        [dataSource]='ZoneList'
        [fields]='ZoneFields'
        placeholder='Select Zone'
        [allowFiltering]='true'
        class="full-width margin-right"
        [value]="SelectedZoneValue"
        (change)='onZoneDropdownListChange($event)'>                    
    </ejs-dropdownlist>
</div>
<div fxflex="row" fxflexorder="3" fxflexorder.gt-md="1" style="order: 3; flex: 1 1 0%; box-sizing: border-box;">
    <ejs-dropdownlist
        id='ThingDropDown'
        #LocationDropDown
        [dataSource]='ThingList'
        [fields]='ThingFields'
        placeholder='Select Asset'
        [allowFiltering]='true'
        class="full-width margin-right"
        [value]="SelectedThingValue"
        (change)='onThingDropdownListChange($event)'>                    
    </ejs-dropdownlist>
</div>
<div fxflex="row margin-right" fxflexorder="4" fxflexorder.gt-md="1" style="order: 3; flex: 1 1 0%; box-sizing: border-box;">
    <ejs-daterangepicker #dateformat [format]="format" [(startDate)]="startDate" [(endDate)]="endDate" class="full-width margin-right"></ejs-daterangepicker>
</div>
<!-- <div class="margin-bottom">
    <div class="containerX mat-card-content">
        <div class="col-lg-12 control-section">
            <ejs-querybuilder #querybuilder class="row" [(rule)]="importRules" (created)="createdControl()">
              <e-columns>
                <e-column field="ThingName" label="Thing Name" type="string"></e-column>
                <e-column field="AssetMacAddress" label="Asset MAC" type="string"></e-column>
                <e-column field="GatewayMacAddress" label="Gateway MAC" type="string"></e-column>
                <e-column field="ZoneName" label="Zone Name" type="string"></e-column>
                <e-column field="FloorName" label="Floor Name" type="string"></e-column>
                <e-column field="Duration" label="Duration" type="number"></e-column>
                <e-column field="EnteredDateTimeStamp" label="Entered DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
                <e-column field="ExitedDateTimeStamp" label="Exited DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
                <e-column field="LastSeenDateTimeStamp" label="Lastseen DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
              </e-columns>
            </ejs-querybuilder>
          </div>          
    </div>
     -->
<div fxLayout="row" fxLayoutAlign="end center" fxFill class="">
    <button mat-button color="primary" (click)="refresh()">Refresh Data</button>
    <button mat-button color="success" (click)="reloadFilters()">Reload Filters </button>
    <button mat-button color="success" (click)="clearFilter()">Clear Filters</button>
  </div>