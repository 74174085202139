import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { BuildingViewModel } from "../../modules/building/building.model";



@Injectable({ providedIn : "root"})
export class BuildingService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    ) {

  }


  List(filter:any={}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/building/getAll",{ params: filter });
  }
  FilterBuildings(model: BuildingViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/building/filterBuildings", model);
  }
  Create(model: BuildingViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/building/create", model);
  }

  GetById(buildingId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/building/get/${buildingId}`);
  }

  GetDetails(buildingId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/building/get-details/${buildingId}`);
  }

  validate(model: BuildingViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/building/validate", model);
  }
  Update(model: BuildingViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/building/update", model);
  }
  Delete(model: BuildingViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/building/delete", model);
  }



}

