import { CURRENT_USER } from "../../shared/constants/session-strings.constant";
import { UserModel } from "../../shared/models/user.model";

/**
 * Returns the current user
 */
function loggedInUser(): any {
  let user: any = {};
  user = JSON.parse(sessionStorage.getItem('currentUser')!);
  return user;
}
export { loggedInUser }

