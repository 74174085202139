<ejs-toast #defaulttoast id='defaulttoast' [position]="position" (created)="onCreate()" (close)="onClose($event)" icon="bi bi-exclamation-circle" (beforeOpen)="onBeforeOpen()">
<ng-template #content>
    <div>
        <div>{{Message}}</div>
        <button ejs-button #toastBtnHide class="e-btn" id="toastBtnHide" (click)='hideToast()'>Okay</button>
    </div>
</ng-template>
<ng-template #title>
    <div>{{Title}}</div>
</ng-template>
</ejs-toast>