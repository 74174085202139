import { Component } from '@angular/core';
import { AuthService } from "../../core/auth/service/auth.service";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  constructor(private authService: AuthService){

  }
  logout(){
    this.authService.logout();
  }
}
