import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() public tittle : string = "Please Confirm";
  @Input() public message : string = "Please Confirm";
  @Output() public onClickResult = new EventEmitter();
  @Input() public confrimButtonText : string = "Confirm";
  @Input() public cancelButtonText : string = "No Thanks";
 
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.tittle=data?.tittle ? data?.tittle : this.tittle ;
      this.confrimButtonText=data?.confrimButtonText ? data?.confrimButtonText : this.confrimButtonText ;
      this.cancelButtonText=data?.cancelButtonText ? data?.cancelButtonText : this.cancelButtonText ;
      this.message=data?.message ? data?.message : this.message ;
  }
  public onNoClick(){
    this.onClickResult.emit(false);
    this.dialogRef.close(false);
  }
  public onYesClick(){
    this.onClickResult.emit(true);
    this.dialogRef.close(true);
  }
}
