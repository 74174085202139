import { Component, ViewEncapsulation, ViewChild, HostListener, ElementRef, Inject, Injector } from '@angular/core';
import { ToastComponent, ToastCloseArgs, ToastPositionModel } from '@syncfusion/ej2-angular-notifications';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { ToastCssEnum, ToastsStyles } from "./toast-css.enum";

@Component({
  selector: 'app-syncfusion-notification',
  templateUrl: './syncfusion-notification.component.html',
  styleUrls: ['./syncfusion-notification.component.scss']
})
export class SyncfusionNotificationComponent {

  @ViewChild('defaulttoast') public toastObj: ToastComponent;
  @ViewChild('toastBtnShow') public btnEleShow: ButtonComponent;

  public position: ToastPositionModel = { X: "Center" };
  public Title :string = "Information";
  public Message :string = "";

  constructor() {
  }
  setTitle(title:string){
    this.Title=title;
  }
  setMessage(message:string){
    this.Message=message;
  }
  public onCreate = (): void => {
      // setTimeout((): void => {
      //     this.toastObj.show();
      // }, 200);
  }

  public onClose = (e: ToastCloseArgs): void => {
      // if (e.toastContainer.childElementCount === 0) {
      //     this.btnEleHide.element.style.display = 'none';
      // }
  }

  public onBeforeOpen = (): void => {
      //this.btnEleHide.element.style.display = 'inline-block';
  }

  // public showToast = (): void => {
  //     this.toastObj.show();
  // }
  public showToast = (title,message,ToastCss:ToastCssEnum): void => {
    this.setMessage(message);
    this.setTitle(title);
    var config = ToastsStyles[ToastCss];
    //console.log(ToastCss,config)
    this.toastObj.show(config);
}
  public hideToast = (): void => {
      this.toastObj.hide('All');
  }

  // @HostListener('document:click', ['$event'])
  // documentClick = (e: MouseEvent): void => {
  //     if (e.target !== this.btnEleShow.element && this.toastObj.target === document.body) {
  //         this.toastObj.hide('All');
  //     }
  // }
}
