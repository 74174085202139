
<div class="mat-card margin-bottom">
    <div class="containerX mat-card-content">

        <div class="row">
            <div class="col-sm-12 col-md-3 mb-1">
                <ejs-dropdownlist  class="fw" 
                    id='BuildingDropDown'
                    #BuildingDropDown
                    [dataSource]='BuildingList'
                    [fields]='BuildingFields'
                    placeholder='Select Building'
                    [allowFiltering]='true'
                    class="full-width "
                    [value]="SelectedBuildingValue" 
                    (change)='onBuildingDropdownListChange($event)'>                    
                </ejs-dropdownlist>
            </div>
            <div class="col-sm-12 col-md-3 mb-1">
                <ejs-dropdownlist 
                    id='FloorDropDown'
                    #FloorDropDown
                    [dataSource]='FloorList'
                    [fields]='FloorFields'
                    placeholder='Select Floor'
                    [allowFiltering]='true'
                    class="full-width "
                    [value]="SelectedFloorValue"
                    (change)='onFloorDropdownListChange($event)'>        
                </ejs-dropdownlist>
            </div>
            <div class="col-sm-12 col-md-3 mb-1">
                <ejs-dropdownlist
                    id='ZoneDropDown'
                    #ZoneDropDown
                    [dataSource]='ZoneList'
                    [fields]='ZoneFields'
                    placeholder='Select Zone'
                    [allowFiltering]='true'
                    class="full-width "
                    [value]="SelectedZoneValue"
                    (change)='onZoneDropdownListChange($event)'>                    
                </ejs-dropdownlist>
            </div>
            <div class="col-sm-12 col-md-3 mb-1">
                <ejs-dropdownlist
                    id='ThingDropDown'
                    #LocationDropDown
                    [dataSource]='ThingList'
                    [fields]='ThingFields'
                    placeholder='Select Asset'
                    [allowFiltering]='true'
                    class="full-width"
                    [value]="SelectedThingValue"
                    (change)='onThingDropdownListChange($event)'>                    
                </ejs-dropdownlist>
            </div>
            <div class="col-sm-12 col-md-3 mb-1">
                <ejs-daterangepicker #dateformat [format]="format" [(startDate)]="startDate" [(endDate)]="endDate" class="full-width"></ejs-daterangepicker>
            </div>
        </div>


    </div>
    <!-- <div class="containerX mat-card-content">
        <div class="col-lg-12 control-section">
            <ejs-querybuilder #querybuilder class="row" [(rule)]="importRules" (created)="createdControl()">
              <e-columns>
                <e-column field="ThingName" label="Thing Name" type="string"></e-column>
                <e-column field="AssetMacAddress" label="Asset MAC" type="string"></e-column>
                <e-column field="GatewayMacAddress" label="Gateway MAC" type="string"></e-column>
                <e-column field="ZoneName" label="Zone Name" type="string"></e-column>
                <e-column field="FloorName" label="Floor Name" type="string"></e-column>
                <e-column field="Duration" label="Duration" type="number"></e-column>
                <e-column field="EnteredDateTimeStamp" label="Entered DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
                <e-column field="ExitedDateTimeStamp" label="Exited DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
                <e-column field="LastSeenDateTimeStamp" label="Lastseen DateTimeStamp" type="date" format="dd/MM/yyyy HH:MM"></e-column>
              </e-columns>
            </ejs-querybuilder>
          </div>          
    </div> -->
    <div class="containerX mat-card-content">
        <div class="d-flex justify-content-center">
            <button mat-button color="primary" (click)="refresh()">Refresh Data</button>
            <button mat-button color="success" (click)="reloadFilters()">Reload Filters </button>
            <button mat-button color="success" (click)="clearFilter()">Clear Filters</button>
        </div>
    </div>
</div>
