
            <div fxLayout="column" fxLayoutAlign="start center" class="fh fw" fxLayoutGap="10px" >
              <div fxLayout="row" fxLayoutAlign="start center" class="" >
                <img src="./assets/icons/user-profile.png" height="50"  style="margin-top:10px; padding-bottom: 5px; padding-top:5px">
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" class="username" >
                {{UserProfile.FullName}}
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" class="email-address" >
                {{UserProfile.EmailAddress}}
              </div>
              <mat-divider></mat-divider>
              <div fxLayout="row" fxLayoutAlign="start start" class="boxp" fxFill>


                <ejs-accordion expandMode='Multiple' class="fw " >
                  <e-accordionitems>
                    <e-accordionitem expanded='true'>
                      <ng-template #header>
                        <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                          <div fxLayout="column" class="" fxLayoutAlign="center center">

                          </div>
                          <div fxLayout="column" class="box" fxLayoutAlign="center center">
                            <div matListItemTitle>Home</div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #content>
                        <div fxLayout="column" class="" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <mat-icon matListItemIcon>dashboard</mat-icon>
                          </div>
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <div matListItemTitle>Overview</div>
                          </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px" >
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <mat-icon matListItemIcon>public</mat-icon>
                          </div>
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <div matListItemTitle>Realtime </div>
                          </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px"  >
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <mat-icon matListItemIcon>location_on</mat-icon>
                          </div>
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <div matListItemTitle>Locate</div>
                          </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px" *ngIf="isAdminUser">
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <mat-icon matListItemIcon>double_arrow</mat-icon>
                          </div>
                          <div fxLayout="column" class="" fxLayoutAlign="center center">
                            <div matListItemTitle>Activity</div>
                          </div>
                        </div>
                        </div>

                      </ng-template>

                    </e-accordionitem>
                      </e-accordionitems>
                      </ejs-accordion>
              </div>
            </div>




        <!-- <ejs-accordion expandMode='Multiple' class="full-width " >
          <e-accordionitems>
            <e-accordionitem expanded='true'>
              <ng-template #header>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">

                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Home</div>
                  </div>
                </div>
              </ng-template>

              <ng-template #content>
                <div fxLayout="column" class="" fxLayoutAlign="center center" fxLayoutGap="5px">
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>dashboard</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Overview</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px" >
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>public</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Realtime </div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px"  >
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>location_on</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Locate</div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px" *ngIf="isAdminUser">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>double_arrow</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Activity</div>
                  </div>
                </div>
                </div>

              </ng-template>

            </e-accordionitem>
            <mat-divider></mat-divider>
            <e-accordionitem expanded='true' *ngIf="isAdminUser">
              <ng-template #header>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">

                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Administration</div>
                  </div>
                </div>
              </ng-template>
              <ng-template #content>
                <div fxLayout="column" class="" fxLayoutAlign="center center" fxLayoutGap="5px">
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px" >
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Buildings</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Floors</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Departments</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Zones</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Locations</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Nodes</div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Tags</div>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>tune</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Assets</div>
                  </div>
                </div>

              </div>
              </ng-template>
            </e-accordionitem>
            <mat-divider></mat-divider>

            <e-accordionitem *ngIf="isAdminUser">
              <ng-template #header>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">

                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Reports</div>
                  </div>
                </div>

              </ng-template>

              <ng-template #content>
                <div fxLayout="column" class="" fxLayoutAlign="center center" fxLayoutGap="5px">
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Platform</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>User Activity</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Movement</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Location</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Reallocation</div>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>assessment</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Enter Exit</div>
                  </div>
                </div>
              </div>

              </ng-template>
            </e-accordionitem>
            <mat-divider></mat-divider>
            <e-accordionitem expanded='false'>
              <ng-template #header>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">

                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <div matListItemTitle>Platform</div>
                  </div>
                </div>

              </ng-template>
              <ng-template #content>

                <div fxLayout="column" class="" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                    <div fxLayout="column" class="" fxLayoutAlign="center center">
                      <mat-icon matListItemIcon>security</mat-icon>
                    </div>
                    <div fxLayout="column" class="" fxLayoutAlign="center center">
                      <a class="link" href="https://wiki.sethala.com/en/platform/security" target="_blank" matListItemTitle>Security</a>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>help_outline</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <a class="link" href="https://wiki.sethala.com/" target="_blank" matListItemTitle>Wiki</a>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>bug_report</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <a class="link" href="https://sethala.atlassian.net/servicedesk/customer/portal/2/group/3/create/16" target="_blank" matListItemTitle>Report a Bug</a>
                  </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="" fxFill fxLayoutGap="5px">
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <mat-icon matListItemIcon>contact_support</mat-icon>
                  </div>
                  <div fxLayout="column" class="" fxLayoutAlign="center center">
                    <a class="link" href="https://sethala.atlassian.net/servicedesk/customer/portal/2" target="_blank" matListItemTitle>Support</a>
                  </div>
                </div>



              </div>

              </ng-template>
            </e-accordionitem>

          </e-accordionitems>
        </ejs-accordion> -->
