import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationToTime'
})
export class DurationToTimePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value ? secondsToDuration(value as number *60) : secondsToDuration(0);
  }

}
export function secondsToDuration(_seconds){
  try {
      var sec_num = parseInt(_seconds, 10); // don't forget the second param
      var hours   : any= Math.floor(sec_num / 3600);
      var minutes : any= Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds : any= sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      
      return hours+':'+minutes+':'+seconds;
  } catch (error) {
      console.log(error)
  } 
  return null
}