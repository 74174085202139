import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './host/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Mgo+DSMBaFt/QHRqVVhkXlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH9SdkdnW3pWdHRdQw==;Mgo+DSMBPh8sVXJ0S0J+XE9AdFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0RiWX1acnFdQmJeVA==;ORg4AjUWIQA/Gnt2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWH9cdHZQTmRfVEE=;OTE3MjkwQDMyMzAyZTM0MmUzMExzbGR4eTROTWlNTnpma0k5VHRxUS9SekFUL2lsaVVKWlhTVnZCVytubm89;OTE3MjkxQDMyMzAyZTM0MmUzMGVXUWlGeExrbzR5ZllOdXJJWk5ya3UydE44WjJ5QUhOeVZFWmVDaXo5ams9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtAVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWH5ecnBXQ2hZU0Rz;OTE3MjkzQDMyMzAyZTM0MmUzMGF4Q042eFZMQzB5dFlaem9XSGlOUkVXVG1sMXB6OUxYMWx0TW91T0Y1WVE9;OTE3Mjk0QDMyMzAyZTM0MmUzMFFrNTNhcTgrNXRRNmpzZlFTVHkwMDFnSDQxTXZQNFF2TVpuSzdHRW1OUTQ9;Mgo+DSMBMAY9C3t2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiWH9cdHZQTmVUWEE=;OTE3Mjk2QDMyMzAyZTM0MmUzMEE4bmwzc0ROV0NncHZSKzRDUDhHczVrR0FJQXYyVjJvWm5nUmdQK2Z6WnM9;OTE3Mjk3QDMyMzAyZTM0MmUzMGN4SHRTbGo4OHBUWFlxUlFRbG14bDJpTGtHWkdMQ0pESTVBb1B5bFBDR0k9;OTE3Mjk4QDMyMzAyZTM0MmUzMGF4Q042eFZMQzB5dFlaem9XSGlOUkVXVG1sMXB6OUxYMWx0TW91T0Y1WVE9');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
