import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateRangePickerComponent } from "@syncfusion/ej2-angular-calendars";
import { QueryBuilderComponent, RuleModel } from "@syncfusion/ej2-angular-querybuilder";
import * as _ from "lodash";
import * as moment from 'moment';
import { BuildingViewModel } from "../../modules/building/building.model";
import { FloorViewModel } from "../../modules/floor/floor.model";
import { ThingViewModel } from "../../modules/thing/thing.model";
import { ZoneViewModel } from "../../modules/zone/zone.model";
import { ReportFilterModel } from "../../shared/models/report-filter.model";
import { ReportFilterProvider } from "../report-filter/repor-filter.provider";
import { Browser } from '@syncfusion/ej2-base';
@Component({
  selector: 'app-vertical-report-filter',
  templateUrl: './vertical-report-filter.component.html',
  styleUrls: ['./vertical-report-filter.component.scss']
})
export class VerticalReportFilterComponent {
  @ViewChild('querybuilder') qryBldrObj: QueryBuilderComponent;
  dataSource: any = [];
  importRules: RuleModel = {
      'condition': 'and',
      'rules': []
      };
  @Input() public CurrentFilter : ReportFilterModel
  @Output() public onSelectedFiltersChanged = new EventEmitter();
  
  public DateRangeObj: DateRangePickerComponent;
  public format: string = 'dd\'\/\'MMM\'\/\'yy hh:mm a';
  public startDate: Date = new Date(moment().utc().subtract('hours', 24).format());
  public endDate: Date = new Date(moment().utc().format());

  public BuildingList = new Array<BuildingViewModel>();
  public BuildingFields: Object = { text: 'BuildingName', value: 'BuildingId' };
  public SelectedBuildingValue: BuildingViewModel;

  public FloorList = new Array<FloorViewModel>();
  public FloorFields: Object = { text: 'FloorName', value: 'FloorId' };
  public SelectedFloorValue: FloorViewModel;

  public ZoneList = new Array<ZoneViewModel>();
  public ZoneFields: Object = { text: 'ZoneName', value: 'ZoneId' };
  public SelectedZoneValue: ZoneViewModel;

  public ThingList = new Array<ThingViewModel>();
  public ThingFields: Object = { text: 'ThingName', value: 'ThingId' };
  public SelectedThingValue: ThingViewModel;
  constructor(
    private _provider : ReportFilterProvider,
  ) { }
  createdControl(): void {
    if (Browser.isDevice) {
        this.qryBldrObj.summaryView = true;
      }
  }
  ngOnInit(): void {
    this.CurrentFilter = this.CurrentFilter ? this.CurrentFilter : {} as any
    this._fetchBuildings();
    this._fetchFloors();
    this._fetchZones();
    this._fetchThings();
  }
  emitFilter(){    
    this.CurrentFilter.Rules = this.importRules;
    this.CurrentFilter.StartDate = this.startDate;
    this.CurrentFilter.EndDate = this.endDate;
    this.onSelectedFiltersChanged.emit(this.CurrentFilter)
  }
  resetAll(){
   // this.importRules.rules = [];
    this.CurrentFilter.BuildingId = undefined;
    this.SelectedBuildingValue = null;
    this.BuildingList = [];
    this.CurrentFilter.FloorId = undefined;
    this.SelectedFloorValue = null;
    this.FloorList =[];
    this.CurrentFilter.ZoneId = undefined;
    this.SelectedZoneValue = null;
    this.ZoneList =[]
    this.CurrentFilter.ThingId = undefined;
    this.SelectedZoneValue = null;
    this.ThingList = [];
    this._fetchBuildings();
    this._fetchFloors();
    this._fetchZones();
    this._fetchThings();
    this.emitFilter();
  }
  onBuildingDropdownListChange(e:any){
    this.CurrentFilter.BuildingId = e.value;
    this.SelectedBuildingValue = e.itemData;

    //Clear OTHER FILEDS
    this.CurrentFilter.FloorId = undefined;
    this.SelectedFloorValue = null;
    this.FloorList =[];
    this.CurrentFilter.ZoneId = undefined;
    this.SelectedZoneValue = null;
    this.ZoneList =[]
    this.CurrentFilter.ThingId = undefined;
    this.SelectedThingValue = null;
    this.ThingList = []
    this._fetchFloors();
    this._fetchZones();
    this._fetchThings();
    this.emitFilter();
  }
  onFloorDropdownListChange(e:any){    
    this.CurrentFilter.FloorId = e.value;
    this.SelectedFloorValue = e.itemData;

    //Clear OTHER FILEDS
    this.CurrentFilter.ZoneId = undefined;
    this.SelectedZoneValue = null;
    this.ZoneList =[]
    this.CurrentFilter.ThingId = undefined;
    this.SelectedThingValue = null;
    this.ThingList = []
    this._fetchZones();
    this._fetchThings();
    this.emitFilter();
  }
  onZoneDropdownListChange(e:any){
    this.CurrentFilter.ZoneId = e.value;
    this.SelectedZoneValue = e.itemData;

    //Clear OTHER FILEDS
    this.CurrentFilter.ThingId = undefined;
    this.SelectedThingValue = null;
    this.ThingList = []
    this._fetchThings();
    this.emitFilter();
  }
  onThingDropdownListChange(e:any){
    this.CurrentFilter.ThingId = e.value;
    this.SelectedThingValue = e.itemData;
    this.emitFilter();
  }
  refresh(){
    this.emitFilter();
  }
  clearFilter(){
    this.resetAll();
  }
  reloadFilters(){
    this._fetchBuildings();
    this._fetchFloors();
    this._fetchZones();
    this._fetchThings();
  }
  _fetchBuildings(){
    this._provider.ListBuildings({}).then((result:any) =>{
      this.BuildingList = result.Data;
      this.SelectedBuildingValue = _.filter(this.BuildingList, a => a.BuildingId === this.CurrentFilter?.BuildingId);
    })
  }
  _fetchFloors(){
    this._provider.ListFloors({BuildingId: this.CurrentFilter?.BuildingId}).then((result:any) =>{
      this.FloorList = result.Data;
      this.SelectedFloorValue = _.filter(this.FloorList, a => a.FloorId === this.CurrentFilter?.FloorId);
    })
  }
  _fetchZones(){
    this._provider.ListZones({
      BuildingId: this.CurrentFilter?.BuildingId,
      FloorId : this.CurrentFilter?.FloorId
    }).then((result:any) =>{
      this.ZoneList = result.Data;
      this.SelectedZoneValue = _.filter(this.ZoneList, a => a.ZoneId === this.CurrentFilter?.ZoneId);
    })
  }
  _fetchThings(){
    this._provider.ListThings({
      BuildingId: this.CurrentFilter?.BuildingId,
      FloorId : this.CurrentFilter?.FloorId,
      ZoneId : this.CurrentFilter?.ZoneId
    }).then((result:any) =>{
      this.ThingList = result.Data;
      this.SelectedThingValue = _.filter(this.ThingList, a => a.ThingId === this.CurrentFilter?.ThingId);
    })
  }
}
