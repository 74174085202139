

<h2 mat-dialog-title>Raw Data History : {{Title}} </h2>
<mat-dialog-content class="mat-typography card" style="width: 80vw; height: 100vh;">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading"  class="fw">
        <mat-progress-bar mode="indeterminate" color="primary" style="height: 2px;" class="fw"  ></mat-progress-bar>
    </div>
    <div class="col-lg-12 control-section e-tab-section card-body">
        <div class="col-sm-12 col-lg-3">
            <label >Fetch Quantity</label>
            <input [(ngModel)]="pageSize" class="e-input" min="5" type="number"/>
        </div>
        <!-- <ejs-daterangepicker #dateformat [format]="format" [(startDate)]="startDate" [(endDate)]="endDate" class="full-width"></ejs-daterangepicker> -->
        <div class="control-section" >
            <ejs-grid #grid id='grid' [dataSource]="history" [allowFiltering]="true" [allowSorting]="true" style="min-height: 600px;" [allowPaging]="true"  (toolbarClick)='toolbarClick($event)' [toolbar]="toolbarOptions"
              [allowExcelExport]='true' [allowPdfExport]='true' [pageSettings]="initialPage" [filterSettings]="filterSettings" enableStickyHeader=true
              gridLines='Horizontal' rowHeight="38" [allowGrouping]="true" [groupSettings]="groupOptions" allowReordering='true'
              [showColumnChooser]='true' [enablePersistence]='false' [allowMultiSorting]='true' 
            >
            <e-columns>
              <!-- <e-column field='Description' headerText='Description' width="200px" [allowFiltering]='true'></e-column> -->
              <e-column field='ReportedDateTimeStamp' headerText='Reported Time' type="date"width="150px" format='yyyy-MM-dd HH:mm'  [allowFiltering]='true'></e-column>
              <e-column field='GatewayDateTimeStamp' headerText='Gateway Time' type="date"width="150px" format='yyyy-MM-dd HH:mm'  [allowFiltering]='true'></e-column>
              <e-column field='GatewayMacAddress' headerText='Gateway Mac' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='DeviceName' headerText='Name' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='AssetMacAddress' headerText='Asset Mac' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='Rssi' headerText='Rssi' type="number" width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='AdvertisingType' headerText='AdvertisingType' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='DecodeData.BatteryVoltage' headerText='BatteryVoltage' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='DecodeData.AccelerometerSensorEquipped' headerText='Acc Equipped' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='DecodeData.AccelerometerSensor' headerText='Accelerometer' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
              <e-column field='DecodeData.MotionTriggerEventCount' headerText='Motion Event #' width="150px" textAlign='Center' [allowFiltering]='true'></e-column>
            </e-columns>
          </ejs-grid>
      </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
 
    <button mat-button color="primary" (click)="bindGridData()">Refresh Data</button>
    <button mat-button (click)="close()">Cancel</button>
</div>

