import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-host',
	template: `<router-outlet></router-outlet>`,
})
export class AppHost {

	constructor(
	private router: Router,

	) {

	}

}
