export enum ToastCssEnum{
    Information,
    Error,
    Success,
    Warning
}
export const ToastsStyles : { [key: string]: Object }[] = [
    { cssClass: 'e-toast-info', icon: 'bi bi-exclamation-circle' },
    { cssClass: 'e-toast-danger', icon: 'bi bi-x-circle' },
    { cssClass: 'e-toast-success', icon: 'bi bi-check-circle' },
    { cssClass: 'e-toast-warning', icon: 'bi bi-exclamation-triangle' }    
];