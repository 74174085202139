import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { ZoneViewModel } from "../../modules/zone/zone.model";



@Injectable({ providedIn : "root"})
export class ZoneService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    ) {

  }


  List(filter:any={}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/getAll", {params:filter});
  }
  FilterZones(model: ZoneViewModel,sort={}): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/filterZones", model,{ params:sort});
  }
  Create(model: ZoneViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/create", model);
  }

  GetById(zoneId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/zone/get/${zoneId}`);
  }

  GetDetails(zoneId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/zone/get-details/${zoneId}`);
  }

  Update(model: ZoneViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/update", model);
  }

  UpdateZoneGeoJsonData(model: ZoneViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/update-zone-geojson", model);
  }

  Delete(model: ZoneViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/delete", model);
  }

  GetFloorPlanData(floorId) :any{
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/floor/getFloorPlanData/${floorId}`);
  }

  validate(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/zone/validate", model);
  }

  CountAssetsHealth(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/thing/getThingPercentageCount");
  }

}

