import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { RawDataProvider } from "./raw-data.provider";
import { ClickEventArgs } from "@syncfusion/ej2-angular-navigations";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { ToastService } from "../../shared/services/toast.service";
import { DateRangePickerComponent } from "@syncfusion/ej2-angular-calendars";
import * as moment from "moment";

@Component({
  selector: 'app-raw-data-history',
  templateUrl: './raw-data-history.component.html',
  styleUrls: ['./raw-data-history.component.scss']
})
export class RawDataHistoryComponent implements OnInit{
  public Title : string;
  @ViewChild('grid') public grid: GridComponent;
  public isLoading : boolean = true;
  history: any = [];
  asset : any ;
  filterSettings = { type: 'Excel' };
  groupOptions = { showGroupedColumn: false };
  initialPage = { pageSizes: true, pageSize: 10 };
   toolbarOptions = [
         { text: 'Show/Hide Columns', tooltipText: 'Columns',  id: 'ColumnChooser', align: 'Left' },
         { text: 'Export to Excel', tooltipText: 'Export to Excel', id: 'ExcelExport', align: 'Left' },
     ];
  public DateRangeObj: DateRangePickerComponent;
  public format: string = 'dd\'\/\'MMM\'\/\'yy hh:mm a';
  public startDate: Date = new Date(moment().utc().subtract('hours', 24).format());
  public endDate: Date = new Date(moment().utc().format());
  pageSize: any= 150;
  error: any;
     constructor(
      public dialogRef: MatDialogRef<RawDataHistoryComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private cdr: ChangeDetectorRef,
      public deviceProvider: RawDataProvider,
      private toastService : ToastService,
    ){
      this.asset = data;
      console.log(this.asset)
    }
  ngOnInit(): void {
    this.bindGridData();
  }

    public close(data:any=null){
      this.dialogRef.close(data)
    }
    toolbarClick(args:ClickEventArgs): void {
      if (args.item.id === 'ExcelExport') {
          this.grid.excelExport();
      }
      if (args.item.id === "ColumnChooser") {
        this.grid.columnChooserModule.openColumnChooser();
    }
  }
  public bindGridData(){
      var query = {
        AssetMacAddress: this.asset.AssetMacAddress,
        // StartDate : this.startDate,
        // EndDate : this.endDate
      }

      if(!this.isLoading)
      this.isLoading = true;      
      this.error = ""
      this.deviceProvider.GetHistory( query, 1, this.pageSize ).then((result:any) =>{
        if(result && result.Success){
          this.history = result.Data
        }else{
          this.error = result?.Message
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      }).catch(ex=>{
        this.isLoading = false;
        this.error = ex.toString();
      })
  }
}



