import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./auth/auth.guard";

const routes: Routes = [
  	{
		path: '',
		redirectTo: '/web/(secure:overview/home)',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes )],
	//imports: [RouterModule.forRoot(routes , {useHash: true })],
	exports: [RouterModule],
})
export class CoreRouting {}
