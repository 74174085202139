import { Injectable } from "@angular/core";

@Injectable({providedIn:"root"})
export class MapProvider {

  constructor() {
  }


  GetMapStyleIndoor(){
    let darkStyle : any[] = [

      {
        "elementType": "geometry",
        "stylers": [
          { "visibility": "off" },
          {
            "hue": "#ff4400"
          },
          {
            "saturation": -100
          },
          {
            "lightness": -4
          },
          {
            "gamma": 0.72
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#323436"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road",
        //"elementType": "labels.icon",
        "stylers": [
          { "visibility": "on" },
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [
          { "visibility": "off" },
          {
            "hue": "#f66213"
          },
          {
            "gamma": 3.1
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#f66213"
          },
          {
            "visibility": "off"
          },
          {
            "weight": 2.5
          }

        ]
      },
      {
        "featureType": "water",
        "stylers": [
          { "visibility": "off" },
          {
            "hue": "#000000"
          },
          {
            "gamma": 0.44
          },
          {
            "saturation": -33
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          { "visibility": "off" }
          ,
          {
            "hue": "#44ff00"
          },
          {
            "saturation": -23
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          { "visibility": "on" },
          {
            "hue": "#007fff"
          },
          {
            "gamma": 0.77
          },
          {
            "saturation": 65
          },
          {
            "lightness": 99
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
          { "visibility": "off" },
          {
            "gamma": 0.11
          },
          {
            "weight": 5.6
          },
          {
            "saturation": 99
          },
          {
            "hue": "#0091ff"
          },
          {
            "lightness": -86
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          { "visibility": "off" },
          {
            "lightness": -48
          },
          {
            "hue": "#ff5e00"
          },
          {
            "gamma": 1.2
          },
          {
            "saturation": -23
          }
        ]
      },
      {
        "featureType": 'transit',
        "elementType": 'labels.icon',
        "stylers": [{
          "visibility": 'off'
        }],
      },
      {
        "featureType": "administrative",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },

      {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
          { "visibility": "off" },
          {
            "saturation": -64
          },
          {
            "hue": "#ff9100"
          },
          {
            "lightness": 16
          },
          {
            "gamma": 0.47
          },
          {
            "weight": 2.7
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          { "visibility": "on" },
          {
            "color": "#222222"
          }
        ]
      },
    ];
    return darkStyle;
  }

  GetMapStyle(){
    let darkStyle : any[] = [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "hue": "#ff4400"
          },
          {
            "saturation": -100
          },
          {
            "lightness": -4
          },
          {
            "gamma": 0.72
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon"
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [
          {
            "hue": "#0077ff"
          },
          {
            "gamma": 3.1
          }
        ]
      },
      {
        "featureType": "water",
        "stylers": [
          {
            "hue": "#000000"
          },
          {
            "gamma": 0.44
          },
          {
            "saturation": -33
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          { "visibility": "off" }
          ,
          {
            "hue": "#44ff00"
          },
          {
            "saturation": -23
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "hue": "#007fff"
          },
          {
            "gamma": 0.77
          },
          {
            "saturation": 65
          },
          {
            "lightness": 99
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "gamma": 0.11
          },
          {
            "weight": 5.6
          },
          {
            "saturation": 99
          },
          {
            "hue": "#0091ff"
          },
          {
            "lightness": -86
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "lightness": -48
          },
          {
            "hue": "#ff5e00"
          },
          {
            "gamma": 1.2
          },
          {
            "saturation": -23
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "saturation": -64
          },
          {
            "hue": "#ff9100"
          },
          {
            "lightness": 16
          },
          {
            "gamma": 0.47
          },
          {
            "weight": 2.7
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#222222"
          }
        ]
      },
    ];
    return darkStyle;
  }

}
