import { Injectable } from "@angular/core";
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
  })
  export class ToastService {  
    durationInSeconds = 3;

    constructor(private _snackBar: MatSnackBar) {}

    public info(message:string,title:string = "Info"){
        this._snackBar.open(`${title} : ${message}`, "Okay", {duration :this.durationInSeconds*1000})
    }
    public success(message:string,title:string = "Success"){
        this._snackBar.open(`${title} : ${message}`, "Okay", {duration :this.durationInSeconds*1000})
    }
    public warn(message:string,title:string = "Warn"){
        this._snackBar.open(`${title} : ${message}`, "Okay", {duration :this.durationInSeconds*1000})
    }
    public error(message:string,title:string = "Error"){
        this._snackBar.open(`${title} : ${message}`, "Okay", {duration :this.durationInSeconds*1000})
    }
}