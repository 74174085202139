import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFilterComponent } from './report-filter/report-filter.component';
import { SyncfusionModule } from "../shared/syncfusion.module";
import { MaterialModule } from "../shared/material.module";
import { VerticalReportFilterComponent } from './vertical-report-filter/vertical-report-filter.component';
import { SyncfusionNotificationComponent } from './syncfusion-notification/syncfusion-notification.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { RawDataHistoryComponent } from './raw-data-history/raw-data-history.component';



@NgModule({
  declarations: [
    ReportFilterComponent,
    VerticalReportFilterComponent,
    SyncfusionNotificationComponent,
    ConfirmDialogComponent,
    RawDataHistoryComponent
  ],
  exports:[ReportFilterComponent,VerticalReportFilterComponent, SyncfusionNotificationComponent, ConfirmDialogComponent, RawDataHistoryComponent],
  imports: [
    CommonModule,
    SyncfusionModule,
    MaterialModule
  ]
})
export class ComponentsModule { }
