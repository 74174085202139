import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { IMqttMessage, MqttService } from "ngx-mqtt";


@Injectable()
export class UserProfileService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider,
    ) {

  }
  GetProfileInformation(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/user/getProfile");
  }

}

