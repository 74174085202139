import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {

    const options: KeycloakOptions = {
      config : environment.keycloak,
      loadUserProfileAtStartUp: true,
      // initOptions: {
      //     onLoad: 'check-sso',
      //     // onLoad: 'login-required',
      //     checkLoginIframe: false
      // },
      initOptions: {        
        onLoad: 'check-sso',
          //silentCheckSsoRedirectUri: window.location.origin + '/assets/verify-sso.html',
          checkLoginIframe: false
      },
      bearerExcludedUrls: []
    };

    return () => keycloak.init(options);
}
