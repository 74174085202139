import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Compiler, NgModule, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as lodash from "lodash";
import { AppLocationModel, AppLocationProvider } from "@Shared/providers/app-location.provider";
import { CURRENT_USER } from "@Shared/constants/session-strings.constant";
@Component({
	selector: 'secure-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class SecureMenuComponent implements OnInit {
  isAdminUser = false;
  MenuItemList =  new Array<MenuItem>();
  UserProfile;
  @Input() public DisplayIconsOnly:any;
  constructor(
		private router: Router,
    private _appLocationProvider: AppLocationProvider,

	) {
    this.buildMenuItems()
	}
  ngOnInit(): void {
    let _appNavigationModel = new AppLocationModel();
    _appNavigationModel.LocationText = this.MenuItemList[0].TextToDisplay;
    _appNavigationModel.LocationPath = "location/home"
    _appNavigationModel.Icon = this.MenuItemList[0].IconUrl;
     this.MenuItemList[0].IsActive = true;
    this._appLocationProvider.broadcast(_appNavigationModel);
    this.UserProfile = JSON.parse(sessionStorage.getItem(CURRENT_USER)!);
    console.log(this.UserProfile);
  }

  async navigateToModule(menuItem: MenuItem){
    let _appNavigationModel = new AppLocationModel();
    _appNavigationModel.LocationText = menuItem.TextToDisplay;
    _appNavigationModel.LocationPath = "location/home"
    _appNavigationModel.Icon = menuItem.ActiveIconUrl;
    await this.switchState(menuItem)
    this._appLocationProvider.broadcast(_appNavigationModel);
    this.router.navigateByUrl(`${menuItem.Url}`)

  }

  async switchState(menuItem: MenuItem){
    this.MenuItemList.forEach((item: MenuItem)=>{
      if(item.Url === menuItem.Url){
        item.IsActive = true;
      } else {
        item.IsActive = false;
      }
    })
  }


  buildMenuItems(){
    this.MenuItemList = [
      {
        TextToDisplay:"Home",
        IconUrl: "./../assets/menu-icons/home.png",
        ActiveIconUrl:"./../assets/menu-icons/home-active.png",
        InactiveIconUrl:"./../assets/menu-icons/home-inactive.png",
        Url: "/secure/home",
        IsActive: false
      },
      {
        TextToDisplay:"Install Node",
        IconUrl: "./../assets/menu-icons/locations.png",
        ActiveIconUrl:"./../assets/menu-icons/location-active.png",
        InactiveIconUrl:"./../assets/menu-icons/location-inactive.png",
        Url: "/secure/install-node",
        IsActive: false
      },
      {
        TextToDisplay:"Install Beacon",
        IconUrl: "./../assets/menu-icons/devices.png",
        ActiveIconUrl:"./../assets/menu-icons/device-active.png",
        InactiveIconUrl:"./../assets/menu-icons/device-inactive.png",
        Url: "/secure/install-tag",
        IsActive: false
      }

    ]

  }




}




export class MenuItem{
  TextToDisplay: string;
  IconUrl: string;
  ActiveIconUrl: string;
  InactiveIconUrl:string;
  IsActive: boolean;
  Url: string;
}
