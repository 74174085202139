import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
@Injectable({providedIn : "root"})
export class ConfigProvider {

  constructor() {
  }

  GetPortalName() {
    return environment.Name;
  }

  GetVersion() {
    return environment.Version;
  }


  GetSethalaIndoorApiUrl(){
    return environment.SethalaIndoorApi.Url;
  }

  GetKhiyaClientId(){
    return environment.SethalaKhiyaApi.ClientId;
  }
  GetKhiyaUrl(){
    return environment.SethalaKhiyaApi.Url;
  }
  GetKhiyaGrantType(){
    return environment.SethalaKhiyaApi.GrantType;
  }

  GetKhiyaRealm(){
    return environment.SethalaKhiyaApi.Realm;
  }


  GetProductionMode() {
    return environment.production;
  }



}
