import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserProfileService } from "./user.service";

@Injectable()
export class UserProfileProvider {
  constructor(
    private userService: UserProfileService,
  ) {
  }

  GetProfileInformation() {
    return new Promise((result, exception) => {
      this.userService.GetProfileInformation().subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
 
}
