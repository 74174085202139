import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreRouting } from './core.routing';
 import { PublicModule } from './../public/public.module';
import { SecureModule } from "./../secure/secure.module";
import { MiscellaneousModule } from "../miscellaneous/miscellaneous.module";
import { AuthModule } from "./auth/auth.module";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
@NgModule({
	imports: [
		CommonModule,
		CoreRouting,
		AuthModule,
		SecureModule,
	],
	declarations: [],
	exports: [
		RouterModule,
	],
	providers: [
	],
})
export class CoreModule { }
