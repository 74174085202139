import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { loggedInUser } from '../helpers/utils';
import { environment } from 'src/environments/environment';
import { ConfigProvider } from "../../shared/providers/config.provider";
import { UserModel } from "../../shared/models/user.model";


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: any= null;
    constructor (private http: HttpClient,private configProvider: ConfigProvider) {
    }

    /**
     * Returns the current user
     */
    public currentUser() {
        if (!this.user) {
            this.user = loggedInUser();
        }
        return this.user;
    }
    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        sessionStorage.removeItem('currentUser');
        this.user = null;
    }
    GetProfileInformation(): any {
        return this.http.get<any>(this.configProvider.GetSethalaIndoorApiUrl() + "/user/getProfile");
      }
}

