import { Injectable } from "@angular/core";
import { BuildingService } from "../../shared/services/building.service";
import { FloorService } from "../../shared/services/floor.service";
import { ThingService } from "../../shared/services/thing.service";
import { ZoneService } from "../../shared/services/zone.service";

@Injectable({providedIn: "root"})
export class ReportFilterProvider {
  
  constructor(
    private _buildingService: BuildingService,
    private _floorService : FloorService,
    private _zoneService : ZoneService,
    private _thingService : ThingService
  ) {
  }

  
  ListBuildings(filter) {
    return new Promise((result, exception) => {
      this._buildingService.List(this.cleanFilters(filter)).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  ListFloors(filter) {
    return new Promise((result, exception) => {
      this._floorService.List(this.cleanFilters(filter)).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  ListZones(filter) {
    return new Promise((result, exception) => {
      this._zoneService.List(this.cleanFilters(filter)).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  ListThings(filter) {
    return new Promise((result, exception) => {
      this._thingService.List(this.cleanFilters(filter)).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  cleanFilters(options){
    for (var key in options) {
        if (options.hasOwnProperty(key)) {
            let value = options[key];
            if (value === undefined) {
                delete options[key]
            }
        }
    }
    return options
  }
}