import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from './../core/core.module';
import { AppHost } from './app.host';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AuthModule } from "../core/auth/auth.module";
import { JwtInterceptor } from "../core/helpers/jwt.interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";




@NgModule({
	declarations: [AppHost],
	imports: [
		BrowserModule,
		RouterModule,
		CoreModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
	//	HttpClientModule,
		MatSnackBarModule,
	],
	providers: [
		//{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	],
	bootstrap: [AppHost],
})
export class AppModule {}
