import { Injectable } from "@angular/core";
import { AssetService } from "../../shared/services/asset.service";

@Injectable({providedIn: "root"})
export class RawDataProvider {
  
  constructor(
    private _assetService: AssetService,
  ) {
  }

  
  GetHistory(filter,pageNumber,pageSize) {
    return new Promise((result, exception) => {
      this._assetService.rawData(filter,pageNumber,pageSize).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

}