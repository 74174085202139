import { Injectable } from "@angular/core";



@Injectable()
export class AppProvider {

  constructor(

  ) {

  }

  GetTokenFromCookie() {

  }
}
