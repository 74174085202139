import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

import { Subscription } from 'rxjs';



type AppLocationCallback = (_appLocationModel: AppLocationModel) => any;

@Injectable()
export class AppLocationProvider {

	private handler = new Subject<AppLocationModel>();

	broadcast(_appLocationModel: AppLocationModel) {
		this.handler.next(_appLocationModel);
	}

	subscribe(callback: AppLocationCallback): Subscription {
		return this.handler
			.subscribe(callback);
	}

}


export class AppLocationModel{
  Icon!: string;
  LocationText!:  string;
  LocationPath!: string;

}


