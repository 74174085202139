import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { CURRENT_USER } from "../../shared/constants/session-strings.constant";
import { AuthenticationService } from "../service/auth.service";

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

  constructor(protected override router: Router, protected override keycloakAngular: KeycloakService, protected authenticationService: AuthenticationService) {
    super(router, keycloakAngular);
  }


  GetProfileInformation(){
    return new Promise((result, exception) => {
      this.authenticationService.GetProfileInformation().subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
  public async isAccessAllowed(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // var result =( await this.GetProfileInformation()) as any;
    // const userLoginInfo =  result?.Success ? result.Data : null //JSON.parse(sessionStorage.getItem("user_profile")!);
    // console.log(userLoginInfo)
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];
    var currentUser = {
      User : await this.keycloakAngular.loadUserProfile(),
      Token : (await this.keycloakAngular.getToken()).toString()
    }
   // console.log(currentUser)
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
    let user= {
      EmailAddress:`${currentUser.User.email}`,
      FullName: `${currentUser.User.firstName} ${currentUser.User.lastName}`
    };

    sessionStorage.setItem(CURRENT_USER, JSON.stringify(user))
    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    // console.log(requiredRoles);
    // console.log(this.roles);
    var rs = requiredRoles.every((role) => this.roles.includes(role))
    //console.log(rs)
    // Allow the user to proceed if all the required roles are present.
    // if( requiredRoles.every((role) => this.roles.includes(role)) ){
    if( requiredRoles.every((role) => this.roles.includes(role)) ){ //&& userLoginInfo.Status == 'Active'){
      return true;
    }
    else{
      return this.router.parseUrl('/web/(secure:forbidden)');
    }
  }

}
/*console.log(requiredRoles);
    console.log(this.roles);
    var rs = requiredRoles.every((role) => this.roles.includes(role))
    console.log(rs)
    // Allow the user to proceed if all the required roles are present.
    // if( requiredRoles.every((role) => this.roles.includes(role)) ){
    var atLeast1 = false;
    for( var role of requiredRoles){
      if(this.roles.includes(role)){
        atLeast1 = true;
        break;
      }
    }
    if( atLeast1 ){
      return true;
    }
    else{
      return this.router.parseUrl('/app/miscellaneous/forbidden');
    }
  */
