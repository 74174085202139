import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "./../services/authentication.service";
import jwt_decode from 'jwt-decode';
import { JwtData } from "./../models/jwt-data.model";
import {JwtPayload  } from "./../models/jwt-payload.model";
import { UserModel } from "../models/user.model";
import * as moment from "moment";
import { CURRENT_USER } from "../constants/session-strings.constant";

@Injectable()
export class AuthenticationProvider {
  constructor(
    private _authenticationService: AuthenticationService,
  ) {
  }


  // SecureLogin(emailAddress, password) {
  //   return new Promise((result, exception) => {
  //     this._authenticationService.secureLogin(emailAddress, password).subscribe(
  //       (response: JwtPayload) => {
  //         try {
  //           let jwtData:JwtData = jwt_decode(response.access_token);
  //           let user: UserModel = {
  //             AccessToken: response.access_token,
  //             AccessTokenExpiresIn: response.expires_in,
  //             ExpiresAt: moment.unix(jwtData.exp).toDate(),
  //             IssuedAt: moment.unix(jwtData.iat).toDate(),
  //             RefreshToken: response.refresh_token,
  //             RefreshTokenExpiresIn: response.refresh_expires_in,
  //             FullName: jwtData.name,
  //             GivenName: jwtData.given_name,
  //             FamilyName: jwtData.family_name,
  //             IsEmailAddressVerified: jwtData.email_verified,
  //             Scope: jwtData.scope,
  //             EmailAddress: jwtData.email,
  //             IsUserValid: true
  //           };
  //           sessionStorage.setItem(CURRENT_USER, JSON.stringify(user));
  //           result(user);
  //         } catch (ex) {
  //           result(false);
  //         }
  //       },
  //       (error: any) => {
  //         result(error.error.error_description);
  //         exception(error.error)
  //       });
  //   });
  // }


}




