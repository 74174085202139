import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Compiler, NgModule, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
	selector: 'secure-home',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss'],
})
export class SecureHomePage implements OnInit {
	constructor(

		private router: Router,

	) {

	}

	public ngOnInit(): void {

	}
	public OpenMenu() {

	}

	public NavigateToSecure() {

	}

}

