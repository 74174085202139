import { NgModule } from '@angular/core';

import { SecureHomePage } from './home/home.page';
import { SecureRouting } from './secure.routing';
import { SecureShellPage } from './shell/secure-shell.page';

import { SharedModule } from "@Shared/shared.module";
import {AppLocationProvider }from "@Shared/providers/app-location.provider";

import { SecureMenuComponent } from "./menu/menu.component";
import { AuthService } from "../core/auth/service/auth.service";
import { ErrorComponent } from "../miscellaneous/error/error.component";
import { ForbiddenComponent } from "../miscellaneous/forbidden/forbidden.component";
import { MiscellaneousComponent } from "../miscellaneous/miscellaneous.component";
import { UserService } from "../modules/user/user.service";
import { UserProvider } from "../modules/user/user.provider";
import { UserProfileProvider } from "./shell/user.provider";
import { UserProfileService } from "./shell/user.service";


@NgModule({

	declarations: [
		SecureShellPage,
		SecureHomePage,
    	SecureMenuComponent,
		MiscellaneousComponent,
		ForbiddenComponent,
		ErrorComponent
	],
	imports: [
		SecureRouting,
		SharedModule

	],
	providers: [
		AppLocationProvider,
		AuthService,
		UserProfileService,
		UserProfileProvider

	],

})
export class SecureModule { }
