import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppProvider } from "./providers/app.provider";
import { ConfigProvider } from "./providers/config.provider";
import { SyncfusionModule } from "./syncfusion.module";
import  { MapProvider } from "./providers/map.provider"
import  { MaterialModule } from "./material.module";
import { AuthenticationProvider } from "./providers/authentication.provider";
import { AuthenticationService } from "./services/authentication.service";
import { ComponentsModule } from "../components/components.module";
import { DurationToTimePipe } from "./pipes/duration-to-time.pipe";
import { DateToTimeAgoPipe } from "./pipes/date-to-time-ago.pipe";
import { JwtInterceptor } from "../core/helpers/jwt.interceptor";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    SyncfusionModule,
    ComponentsModule
  ],
  declarations: [
    DurationToTimePipe,
    DateToTimeAgoPipe
  ],

  providers: [
    ConfigProvider,
    AppProvider,
    MapProvider,
    AuthenticationProvider,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    SyncfusionModule,
    ComponentsModule,
    DurationToTimePipe,
    DateToTimeAgoPipe,
  ],

})
export class SharedModule { }
